import React, { Component } from 'react';
import Row from 'react-bootstrap/Row'
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'

export class JournalCard extends Component {
    static displayName = JournalCard.name;

    render() {
        return (
            <Card>
                <Accordion.Toggle as={Card.Header} eventKey={this.props.journal.key}>
                    <Card.Body className="list-group-item">
                        <h5>{this.props.journal.title} ({this.props.journal.key})</h5>
                        <div className="btn btn-outline-success btn-sm float-right">View/Hide Details</div>
                        <div>
                            <img src={"https://journals.sagepub.com/action/showCoverImage?journalCode=" + this.props.journal.key} height="80px" className="mr-5" alt="{this.props.journal.title} Cover" />
                            <span className="text-center">
                                {this.props.journal.impactFactor && <span className="mr-2">Impact Factor: {this.props.journal.impactFactor}</span>}
                                {this.props.journal.oaType === 'sub' && <img src="images/subscription.png" height="30 px" className="mr-2" alt="Subscription" />}
                                {(this.props.journal.oaType === 'oa' || this.props.journal.sageChoice) && <img src="images/oa.png" height="30 px" className="mr-2" alt="Open Access" />}
                                {this.props.journal.sagePath && <span className="mr-2"><img src="images/sagepath.png" height="30 px" className="mr-2" alt="Sage Path" /></span>}
                            </span>
                        </div>
                    </Card.Body>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={this.props.journal.key}>
                    <Card.Body>
                        <div>
                            <a type="button" className="btn btn-outline-success btn-sm float-right" target="_blank" rel="noopener noreferrer" href={this.props.journal.subscriptionSite}>Submit Article</a>
                            <a type="button" className="btn btn-outline-success btn-sm float-right mr-3" target="_blank" rel="noopener noreferrer" href={"https://journals.sagepub.com/author-instructions/" + this.props.journal.key}>Submission Guidelines</a>
                            <p><strong>Relevant Subject(s):</strong> {this.props.journal.subject}</p>
                            {this.props.journal.processingCharge && <p><strong>Article Processing Charge:</strong> ${this.props.journal.processingCharge} usd</p>}
                            {this.props.journal.indexing && <p><strong>Indexed by:</strong> {this.props.journal.indexing}</p>}
                            {this.props.journal.description && <p>{this.props.journal.description}</p>}
                            <p><strong>Relevant Articles:</strong></p>
                        </div>
                        <Row>
                            {this.props.journal.articles.map(article => {
                                return article && (
                                    <a key={article.doi} className="list-group-item list-group-item-action" target="_blank" rel="noopener noreferrer" href={"https://dx.doi.org/" + article.doi}>
                                        <i>{article.title}</i>
                                    </a>
                                )
                            })}
                        </Row>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        );
    }
}