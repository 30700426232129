import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';

export class NoResults extends Component {
    static displayName = NoResults.name;

    render() {
        return (
            <Container>
                <h4>No results for this search.</h4>
                <h6>Possible reasons:</h6>
                <ol>
                    <li>Insufficient or irrelevant title/keywords</li>
                    <li>No relevant journals (if you applied filters, consider unselecting them and running the search again)</li>
                    <li>Tool unable to find journals (e.g., topic might be relevant but might not be published enough in our journals yet)</li>
                </ol>
                <h6>What to do:</h6>
                <ol>
                    <li>Visit the full list of <a href="https://journals.sagepub.com/action/showPublications" target="_blank" rel="noopener noreferrer">SAGE Journals</a>, or submit to <a href="https://mc.manuscriptcentral.com/sagepath" target="_blank" rel="noopener noreferrer">SAGE Path</a> and ask for a recommendation</li>
                    <li>Check title and keywords to make sure all relevant concepts are included</li>
                    <li>If filters selected, consider unselecting them and broadening your preferences</li>
                </ol>
                <p>Check out the How it Works and FAQs page for details</p>
                <p>Please send any such feedback via the contact button or survey below so we can improve our tool further</p>
            </Container>
        );
    }
}
