import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import { Alert } from './Alert';
import { Header } from './Header';
import { Footer } from './Footer';

export class Layout extends Component {
    static displayName = Layout.name;

    render() {
        return (
            <>
                <Alert />
                <Container>
                    <Header />
                    {this.props.children}
                    <Footer />
                </Container>
            </>
        );
    }
}
