import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';
import { faListAlt } from '@fortawesome/free-solid-svg-icons';
import { faEnvelopeOpen } from '@fortawesome/free-regular-svg-icons';

export class Footer extends Component {
    static displayName = Footer.name;

    getYear() {
        return new Date().getFullYear();
    }

    render() {
        return (
            <footer className="page-footer font-small blue pt-4">
                <Container>
                    <Row>
                        <Col sm>
                            <a href="https://journals.sagepub.com/page/journal-recommender-faqs" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faQuestion} /> How it works and FAQs</a>
                        </Col>
                        <Col sm>
                            <a href="https://journals.sagepub.com/action/showPublications" target="_blank" className="float-right" rel="noopener noreferrer"><FontAwesomeIcon icon={faListAlt} /> List of all Sage Journals</a>
                        </Col>
                    </Row>
                    <div className="footer-copyright text-center py-5">&copy; {this.getYear()}, <a href="https://sagepub.com" target="_blank" rel="noopener noreferrer">Sage Publications</a></div>
                </Container>
            </footer>
        );
    }
}
