import React, { Component } from 'react';

export class Header extends Component {
    static displayName = Header.name;

    render() {
        return (
            <header className="clearfix">
                <br />
                <h2>Journal Recommender<span className="float-right float-center"><img src="images/sagelogo.svg" height="60" alt="Sage logo" /></span></h2>
            </header>
        );
    }
}
