import React, { Component } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export class AlsoConsider extends Component {
    static displayName = AlsoConsider.name;

    render() {
        return (
            <>
                <Row className="border-top mt-3">
                    <h5>You may also consider one of our multidisciplinary open access journals</h5>
                </Row>
                <Row className="border-bottom mt-3">
                    <Col>
                        <div className="view overlay z-depth-1-half">
                            <img src="https://journals.sagepub.com/action/showCoverImage?journalCode=SCI" className="img-fluid" alt="" />
                            <a href="https://journals.sagepub.com/author-instructions/SCI" target="_blank" rel="noopener noreferrer">
                                <div className="mask rgba-white-light">Science Progress</div>
                            </a>
                        </div>
                    </Col>
                    <Col>
                        <div className="view overlay z-depth-1-half">
                            <img src="https://journals.sagepub.com/action/showCoverImage?journalCode=SMO" className="img-fluid" alt="" />
                            <a href="https://journals.sagepub.com/author-instructions/SMO" target="_blank" rel="noopener noreferrer">
                                <div className="mask rgba-white-light">Sage Open Medicine</div>
                            </a>
                        </div>
                    </Col>
                    <Col>
                        <div className="view overlay z-depth-1-half">
                            <img src="https://journals.sagepub.com/action/showCoverImage?journalCode=SCO" className="img-fluid" alt="" />
                            <a href="https://journals.sagepub.com/author-instructions/IMR" target="_blank" rel="noopener noreferrer">
                                <div className="mask rgba-white-light">Sage Open Medical Case Reports</div>
                            </a>
                        </div>
                    </Col>
                    <Col>
                        <div className="view overlay z-depth-1-half">
                            <img src="https://journals.sagepub.com/action/showCoverImage?journalCode=SGO" className="img-fluid" alt="" />
                            <a href="https://journals.sagepub.com/author-instructions/SGO" target="_blank" rel="noopener noreferrer">
                                <div className="mask rgba-white-light">Sage Open (humanities and social sciences)</div>
                            </a>
                        </div>
                    </Col>
                    <Col>
                        <div className="view overlay z-depth-1-half">
                            <img src="https://journals.sagepub.com/action/showCoverImage?journalCode=INQ" className="img-fluid" alt="" />
                            <a href="https://journals.sagepub.com/author-instructions/INQ" target="_blank" rel="noopener noreferrer">
                                <div className="mask rgba-white-light">INQUIRY: The Journal of Health Care Organization, Provision, and Financing</div>
                            </a>
                        </div>
                    </Col>
                </Row>
            </>
        );
    }
}
