import React, { Component } from 'react';
import { JournalCard } from './JournalCard';
import { NoResults } from './NoResults';
import { AlsoConsider } from './AlsoConsider';
import Container from 'react-bootstrap/Container'
import Accordion from 'react-bootstrap/Accordion'
import Select from 'react-select';
import queryString from 'query-string';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

export class Home extends Component {
    constructor(props) {
        super(props);

        const defaults = queryString.parse(window.location.search);
        this.state = {
            title: '',
            keywords: '',
            isPath: defaults.path === 'true',
            indexer: Array.isArray(defaults.indexer) ? defaults.indexer : defaults.indexer ? [defaults.indexer] : [],
            subject: Array.isArray(defaults.subject) ? defaults.subject : defaults.subject ? [defaults.subject] : [],
            type: defaults.type || 'all',
            indexers: [],
            subjects: [],
            journals: null
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        fetch('recommendation/indexers')
            .then(response => response.json())
            .then(result => this.setState({
                indexers: result.map(x => { return { value: x, label: x }; })
            }));
        fetch('recommendation/subjects')
            .then(response => response.json())
            .then(result => this.setState({
                subjects: result.map(x => { return { value: x, label: x }; })
            }));
    }

    handleChange(event) {
        const target = event.target;
        this.setState({
            [target.name]: target.type === 'checkbox' ? target.checked : target.value
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        if (this.state.title.length === 0 && this.state.keywords.length === 0) {
            this.setState({
                journals: null
            });
            return;
        }

        var url = 'recommendation?title=' + encodeURIComponent(this.state.title) + "&keywords=" + encodeURIComponent(this.state.keywords) + "&type=" + encodeURIComponent(this.state.type);
        if (this.state.isPath) {
            url += '&isPath=true';
        }
        for (var i = 0; i < this.state.subject.length; i++) {
            url += '&subject=' + encodeURIComponent(this.state.subject[i])
        }
        for (var j = 0; j < this.state.indexer.length; j++) {
            url += '&index=' + encodeURIComponent(this.state.indexer[j])
        }

        fetch(url)
            .then(response => response.json())
            .then(result => this.setState({
                journals: result
            }))
            .catch(e => console.log(e));
    }

    render() {
        return (
            <>
                <Container>
                    <p>Find relevant journals for your manuscript, verify against aims &amp; scope, and submit to one directly or use <a href="http://mysagepath.com" target="_blank" rel="noopener noreferrer">Sage Path</a>.</p>
                    <form onSubmit={this.handleSubmit}>
                        <Container>
                            <textarea className="form-control" rows="1" placeholder="Manuscript Title" name="title" value={this.state.title} onChange={this.handleChange} />
                        </Container>
                        <Container className="mt-2">
                            <textarea className="form-control" rows="1" placeholder="Keywords (at least 5)" name="keywords" value={this.state.keywords} onChange={this.handleChange} />
                        </Container>
                        <Container className="mt-2">
                            <input type="checkbox" data-toggle="toggle" id="isPath" name="isPath" onChange={this.handleChange} checked={this.state.isPath} /><label htmlFor="isPath">&nbsp; Limit to journals in <a href="http://mysagepath.com" target="_blank" rel="noopener noreferrer">Sage Path</a> (our free article transfer service)</label>
                            <Select
                                options={this.state.subjects}
                                defaultValue={this.state.subject.map(x => ({ label: x, value: x }))}
                                isMulti
                                placeholder="Select Subjects (3-5 recommended)"
                                styles={{ control: base => ({ ...base, marginTop: 15 }) }}
                                onChange={(selected) => { this.setState({ subject: (selected || []).map(x => x.value) }) }}
                            />
                            <Select
                                options={this.state.indexers}
                                defaultValue={this.state.indexer.map(x => ({ label: x, value: x }))}
                                isMulti
                                placeholder="Indexing (can select multiple)"
                                styles={{ control: base => ({ ...base, marginTop: 15 }) }}
                                onChange={(selected) => { this.setState({ indexer: (selected || []).map(x => x.value) }) }}
                            />
                            <Container className="mt-3">
                                <strong>Journal Type</strong>
                                <div className="form-check">
                                    <input type="radio" className="form-check-input" id="jrnlTypeAll" name="type" value="all" checked={this.state.type === 'all'} onChange={this.handleChange} />
                                    <label className="form-check-label" htmlFor="jrnlTypeAll">All Sage Journals</label>
                                </div>
                                <div className="form-check">
                                    <input type="radio" className="form-check-input" id="jrnlTypeOa" name="type" value="oa" checked={this.state.type === 'oa'} onChange={this.handleChange} />
                                    <label className="form-check-label" htmlFor="jrnlTypeOa">Gold Open Access Journals <img src="images/oa.png" alt="" height="24" className="mr-2" /></label>
                                </div>
                                <div className="form-check">
                                    <input type="radio" className="form-check-input" id="jrnlTypeSub" name="type" value="sub" checked={this.state.type === 'sub'} onChange={this.handleChange} />
                                    <label className="form-check-label" htmlFor="jrnlTypeSub">Subscription Journals (most offer hybrid OA option) <img src="images/subscription.png" alt="" height="24" className="mr-2" /></label>
                                </div>
                            </Container>
                        </Container>
                        <Container className="pt-2 clearfix">
                            <button className="btn btn-primary float-right" type="submit"><FontAwesomeIcon icon={faSearch} /> Find Journals</button>
                        </Container>
                    </form>
                </Container>
                {this.state.journals !== null &&
                    <Container className="pt-3 pb-4">
                        <h4>Results (most relevant first)</h4>
                        <Container className="mb-2">
                            <p>&bull; To refine results, use filters and re-run search</p>
                        </Container>
                        {this.state.journals.length > 0 ? <Accordion>{this.state.journals.map(journal => <JournalCard key={journal.key} journal={journal} />)}</Accordion> : <NoResults />}
                        <AlsoConsider />
                    </Container>
                }
            </>
        );
    }
}