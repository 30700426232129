import React, { Component } from 'react';

export class Alert extends Component {
    static displayName = Alert.name;

    render() {
        return (
            <div className="alert alert-primary" style={{ background: '#d9edf7', color:'#fff' }} role="alert">
                <p style={{ color: '#555' }}>
                    <strong>Sage’s Gold Open Access journals welcome submissions to curated Special Collections. Please refer to the <a target="_blank" rel="noopener noreferrer" style={{ color: '#0067C7' }} href="https://journals.sagepub.com/special-collections-discipline">Special Collections Discipline page</a> to browse open Special Collections by area of interest.</strong>
                </p>
            </div>
        );
    }
}
